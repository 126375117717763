.selector-checkboxs{
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.conselor-check{
    
}
.conselor-check-disabled{
    color: #E5E5E5;
}

.schedule-container{
    
}

.days-selector{
    display: flex;
    justify-content: space-between;
    background: #FFFFFF;
    box-shadow: 4px -4px 4px rgba(0, 0, 0, 0.25), -4px 0px 4px rgba(0, 0, 0, 0.25), 4px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 8px 16px;
}

.day {
    cursor: pointer;
    transition: all 0.3ms;
}

.active-day{
    color: #F1C40F;
    border-bottom: solid #F1C40F 1px;
    transition: all 0.3ms;
}

.hours-selector{
    display: flex;
    /* justify-content: space-between; */
    padding: 8px 16px;
    margin-top: 24px;
    flex-wrap: wrap;
}

.hour {
    background: #FFFFFF;
    box-shadow: 4px -4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: 139px;
    height: 37px;
    cursor: pointer;
    transition: all 0.3ms;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 8px 38px;
}

.hour:hover{
    background: #EBF0F4;
    box-shadow: none;
}

.active-hour{
    background: #F1C40F;
    color: white;
    transition: all 0.3ms;
    box-shadow: none;
}
.active-hour:hover{
    background: #F1C40F;
    color: white;
    transition: all 0.3ms;
}