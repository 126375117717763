.session-enrollment{
    display: flex;
    justify-content: space-between;
}

.session-enrollment .information{
    flex: 1;
    flex-direction: column;
    justify-content: start;
    padding: 0 24px;
}

.title {
    line-height: 10px;
    margin-top: 32px;
}
