.popup-container{
    display: flex;
    flex: 1;
    position: absolute;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
}

.popup-backdrop{
    position: absolute;
    background-color: white;
    opacity: 0.7;
    width: 100vw;
    height: 100vh;
    z-index: 2;
}

.popup-content{
    z-index: 3;

    width: 444px;
    height: 400px;

    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    box-shadow: 4px 2px 4px rgba(0, 0, 0, 0.25), -4px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    position: absolute;

    text-align: center;
    /* background-color: red; */
}

.role-selector{
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-around;
    width: 50%;
    border-radius: 80px;
    box-shadow: 4px 2px 24px rgba(116, 158, 205, 0.35);
    padding: 12px;
    margin-bottom: 24px;
    margin-top: 24px;
    color: rgb(32, 42, 54);
}

.role-selector .role{
    cursor: pointer;
    /* border-bottom: 1px solid rgba(116, 158, 205, 0.35); */
    color: rgba(116, 158, 205, 1);
}

.active-role{
    /* border-bottom: 1px solid rgba(116, 158, 205, 0.35); */
    color: rgba(116, 158, 205, 1);
}

.toggle{
    margin-left: -128px;
    margin-top: -12px;
    background: #4E73DF;
    color: white;
    width: 120px;
    height: 49px;
    border-radius: 32px;
    position: absolute;
    align-items: center;
    justify-content: center;
    display: flex;
    transition: all .2s;
}

.toggle.is-konselor-selected{
    margin-left: 128px;
    transition: all .2s;
}

.role-item{
    position: absolute
}

.popup-forms{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 80px;
    width: 100%;
    align-items: center;
}

.popup-content .popup-forms input{
    background: #eaeaea;
    /* box-shadow: 0px 4px 4px #769FCD; */
    border-radius: 90px;
    border: none;
    padding: 8px 12px;
    width: 50%;
}



textarea:focus, input:focus{
    box-shadow: 0px 4px 4px #769FCD;
    outline: none;
}

.popup-content .popup-forms input:focus{
    border-width: 8px;
    background: white;
}

.popup-content .popup-title{
    font-weight: 600;
    font-size: 20px;
    line-height: 50px;
}

.button-submit{
    width: 55%;
    margin-top: 8px;
}

.popup-content .button{
    box-shadow: none;
}

.popup-content .button:hover{
    box-shadow: 0px 4px 4px #769FCD;
}

.popup-content .popup-forms input{
    color: rgba(65, 64, 64, 0.87);
}





.ask-account{
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-text{
    color: #769FCD;
}

.button-text:hover{
    cursor: pointer;
    text-decoration: underline;
}

.popup-content label{
    color: #769FCD;
    margin-left: 8px;
    cursor: pointer;
}
.popup-content label:hover{
    text-decoration: underline;
}


.data-form{
    /* padding-left: 24px; */
    /* border-left: 1px solid #E5E5E5; */
}
.data-form-input{
    text-align: left;
    margin-top: 8px;
}

.data-form-title{
    font-weight: 600;
}

.data-form-avatar{
    object-fit: cover;
}

.data-form-input input{
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    box-shadow: none;
    padding: 12px 0;
    transition: all 0.2s;
    width: 240px
}

.data-form-input input{
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    box-shadow: none;
    padding: 12px 4px;
    transition: all 0.2s;
    width: 340px
}

.data-form-input input:focus{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.data-form-input select{
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    box-shadow: none;
    padding: 12px 0;
    transition: all 0.2s;
    width: 340px
}

.data-form-input > textarea{
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    box-shadow: none;
    padding: 4px 0;
    transition: all 0.2s;
    width: 340px;
    min-height: 80px;
}

.data-form-input select:focus{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.data-form-input textarea:focus{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.data-form-avatar{
    width: 100px;
    height: 100px;
    background-color: gray;
    border-radius: 400px;
    margin-bottom: 16px;
    margin-top: 16px;
}

.material-input input{
    padding: 8px 4px;
    height: max-content;
}

.form-login input{
    color: red
}