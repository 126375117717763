.session-container{
    display: flex;
    flex: 1;
}

.no-session{
    align-items: center;
    justify-content: center;
    text-align: center;
}

.no-session h2{
    font-weight: 300;
}

.session-ready{
    text-align: center;
}