.step-container{
    background: rgba(229, 229, 229, 0.35);
    padding: 24px;
    display: flex;
    flex-direction: column;
    margin: 36px;
    margin-left: 0px;
    margin-top: 0px;
    flex: 0.6;
}

.step-container h3 {
    font-weight: 600;
    font-size: 40px;
    line-height: 0px;
}



.step-card{
    background: rgba(229, 229, 229, 0.35);
    margin: 12px 0;
    padding: 8px;
    transition: all 0.4s;
    color: #868181
}

.active{
    background: white;
    box-shadow: 6px 6px 4px rgba(0, 0, 0, 0.25);
}

.step-content{
    flex: 1;
}

.step-content .title{
    font-weight: 600;
    font-size: 25px;
    line-height: 50px;
    letter-spacing: 0.08em;

    color: #000000;
}

.step-content .description{
    font-size: 16px;
}


