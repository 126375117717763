.pagination{
    display: flex;
    height: 24px;
    align-items: center;
    
}
.current-page{
    font-size: 14px;
    color: #868181;
    margin-right: 8px;
}

.pagination select{
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: none;
    width: 42px;
}

.pagination select:focus{
    border: none;
}