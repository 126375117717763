.dashboard{
    overflow-x: hidden;
    overflow-y: hidden;
    max-height: 100vh;
}

.content{
    padding: 8px;
    display: flex;
    height: 90vh;
    flex: 1;
    flex-direction: row;
}

#content{
    padding: 8px;
    display: flex;
    height: 90vh;
    flex: 1;
    flex-direction: row;
}

.horizontal {
    flex-direction: row;
}



body{
    font-family: 'Hind Vadodara', sans-serif;
}

.section-header{
    flex: 1,
}

.section-wraper{
    flex: 1;
    padding-bottom: 100px;
}

.section-wraper{
    display: flex;
    flex-direction: row;
    height: 340px;
    padding: 24px;
    padding-top: 58px;
}

.loading-container{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}


.conselor-stats{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 32px;
    height: 140px;
    width: 500px;
    background: #FFFFFF;

    box-shadow: 4px -4px 4px #E5E5E5, 4px 4px 4px #E5E5E5, -4px 0px 4px #E5E5E5;
    border-radius: 15px;
}

.conselor-stats-child{
    display: flex;
    border-left: 2px solid #E5E5E5;
    flex: 1;
    justify-content: space-around;
    align-items: center;
}


.case-conference-count{
    padding: 24px;
    /* width: 40%; */
    margin: 0 24px;
    text-align: center;
    margin-top: 24px;
    background: #FFFFFF;
    box-shadow: 2px -2px 2px rgba(0, 0, 0, 0.25), 2px 2px 2px rgba(0, 0, 0, 0.25), -2px 0px 2px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
}

.hover:hover{
    box-shadow: 4px -4px 4px rgba(0, 0, 0, 0.25), 4px 4px 4px rgba(0, 0, 0, 0.25), -4px 0px 4px rgba(0, 0, 0, 0.25);
}

.conselor-edit-container{
    display: flex;
    flex-direction: column;
    padding: 8px 0;
    padding-bottom: 100px;
    box-sizing: border-box;
    align-items: center;
    margin: auto;
    width: 80vw;
    height: 80vh;
    background: #FFFFFF;
    box-shadow: -2px 0px 2px rgba(0, 0, 0, 0.25), 2px 2px 2px rgba(0, 0, 0, 0.25), 2px -2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 15px;;
    overflow-y: scroll;
}

input{
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 8px;
}

@media only screen and (max-width: 768px){
    .section-wraper{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0;
    }
}

.jadwal-item .jadwal-form select{
    padding: 7px;
    background: #FFFFFF;
    border: none;
    border: 1px solid #E5E5E5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 200px;
}
.jadwal-item .jadwal-form select:focus{
    outline: none;
    border: none;

}

.jadwal-item .jadwal-add{
    display: flex;;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    box-sizing: border-box;
    margin-top: 24px;
    padding: 4px;
    cursor: pointer;
    color: white;
}

.jadwal-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.jadwal-form{
    padding: 8px;
}
.fab-add{
    position: absolute;
    bottom: 20vh;
    right: 12vw;
    display: flex;
    background-color: #F1C40F;
    padding: 8px;
    cursor: pointer;
    border-radius: 24px;
    /*box-sizing: border-box;*/
}

.button-s{
    font-size: 120px;
    width: 54px;
    width: 20px;
}