.hapus-pengumuman-popup{
    background-color: white;
    z-index: 5;
    padding: 42px;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
}
.hapus-pengumuman-actions{
    display: flex;
}
.hapus-pengumuman-action{
    /*width: 42px;*/
    padding: 8px;
}
.hapus-pengumuman-title{
    font-weight: bold;
    font-size: 24px;
}


.option{
    display: flex;
    flex-direction: column;
    padding-left: 24px;
}
.option-head{
    border: 1px solid #E5E5E5;
    width: 100%;
    padding: 8px 12px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    /* background-color: red; */
}

.date-picker{
    display: flex;
    flex-direction: row-reverse;
}

.date-picker img{
    position: absolute;
    width: 16px;
    height: 16px;
    object-fit: contain;
    padding: 8px;
}

.option-search{
    display: flex;
}

.option-search input{
    background: #FFFFFF;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
    border-radius: 90px;
    border: none;
    height: 32px;
    width: 100%;
    padding-left: 38px;
    box-sizing: border-box;
}

.option-search img {
    width: 20px;
    height: 20px;
    margin-top: 8px;
    margin-left: 8px;
    object-fit: contain;
    position: absolute;
}

.option-search input:focus{
    background: #FFFFFF;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
    border-radius: 90px;
}
.option input[type=text]{
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    height: 32px;
    margin-bottom: 24px;
}

.option input[type=text]:focus{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.option-body{
    width: 100%;
    overflow-y: scroll;
    padding-right: 24px;
}


.option-card{
    display: flex;
    justify-content: space-between;
    background: #F1C40F;
    height: 86px;
    width: 300px;
    padding: 12px;
    box-sizing: border-box;
    color: #FFFFFF;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
    transition: all .2s;
    cursor: pointer;
    margin-bottom: 24px;
}

.option-head select{
    border-radius: 4px;
    padding: 4px;
    border: none;
    background: #FFFFFF;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
}

.option-head select:hover{
    background: #FFFFFF;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
}

.option-card-icon{
    width: 25%;
}

.option-card-body{
    width: 75%;
}

.option-body table{
    border: none;
    /* width: 100%; */
}

.option-item-separator{
    margin-bottom: 12px;
}

.option-item{
    margin-bottom: 24px;
    padding-bottom: 1em;
    background: #FFFFFF;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
    transition: all 0.2s;
}
.option-item:hover{
    margin-bottom: 24px;
    padding-bottom: 1em;
    background: #FFFFFF;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
}

.option table tr td{
    /* min-width: 120px; */
    padding: 12px 24px;
    border: none;
    font-size: 12px;
    width: 100px;
}

.option-card-icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.option-card-disable{
    display: flex;
    justify-content: space-between;
    background: #FFFFFF;
    height: 86px;
    width: 300px;
    padding: 12px;
    box-sizing: border-box;
    color: #F1C40F;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
    transition: all .2s;
    cursor: pointer;
    margin-bottom: 24px;
}

.option-wrapper{
    display: flex;
    flex-direction: column;
    flex: 1;
    border-right: 1px solid #E5E5E5;
    padding-right: 24px;
    padding-top: 48px;
}

.option-card-title{
    font-weight: 600;
}

.option-card-description{
    font-size: 12px;
}

.option-card-disable:hover{
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
}

.stat-single-count{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    box-shadow: 4px -4px 4px #E5E5E5, 4px 4px 4px #E5E5E5, -4px 0px 4px #E5E5E5;
    border-radius: 15px;
    height: 120px;
    flex-grow: 1;
    margin: 24px;
    padding-top: 24px;
}

.stat-title{
    font-weight: 500;
    font-size: 15px;
}

.stat-count{
    font-size: 80px;
    font-weight: bold;
    color: #F1C40F;
}

.stat-multi-count{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    background-color: white;
    box-shadow: 4px -4px 4px #E5E5E5, 4px 4px 4px #E5E5E5, -4px 0px 4px #E5E5E5;
    border-radius: 15px;
    margin: 24px;
}

.stat-chart{
    background: #FFFFFF;
    box-shadow: 4px -4px 4px #E5E5E5, 4px 4px 4px #E5E5E5, -4px 0px 4px #E5E5E5;
    border-radius: 15px;
    width: 250px;
    height: 250px;
    margin: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.histori-sesi-popup{    
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
}
.histori-sesi-popup-card{
    z-index: 5;
    display: flex;
    justify-content: center;
    padding: 24px;
    height:80vh;
    width:90vw;
    background: #FFFFFF;
    box-shadow: -4px 2px 4px rgba(0, 0, 0, 0.25), 4px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    overflow-y: scroll;
}

.histori-sesi-popup-card .table{
    width: 100%;
    /* overflow-y: scroll; */
    /* height: 100%;
     */
    /* text-align: center; */
}
.histori-sesi-popup-card .table .table-row{
    width: 100%;
    display: flex;
    /* margin-bottom: 2px; */
}
.histori-sesi-popup-card .table .table-row{
    display: flex;
}
.histori-sesi-popup-card .table .table-data{
    display: flex;
    flex: 1;
    /* text-align: center; */
}
.histori-sesi-popup-card .table .table-header{
    font-weight: bold;
}


.report-card .table{
    width: 100%;
    text-align: center;
}
.report-card .table .table-row{
    width: 100%;
    display: flex;
}
.report-card .table .table-row{
    display: flex;
}
.report-card .table .table-data{
    display: flex;
    flex: 1;
    /* text-align: center; */
}
.report-card .table .table-header{
    font-weight: bold;
}


.histori-sesi-popup-backdrop{
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    opacity: 0.5;
}

.edit-pengunguman-popup{
    z-index: 5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 24px;
    height:80vh;
    width:90vw;
    background: #FFFFFF;
    box-shadow: -4px 2px 4px rgba(0, 0, 0, 0.25), 4px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    overflow-y: scroll;
}

.customDatePickerWidth, 
.customDatePickerWidth > div.react-datepicker-wrapper, 
.customDatePickerWidth > div > div.react-datepicker__input-container
.customDatePickerWidth > div > div.react-datepicker__input-container input {
   width: 140px;
   margin-left: 4px;
   
}


.admin-login-popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 8px;
    border-radius: 8px;
    box-shadow: -4px 2px 4px rgba(0, 0, 0, 0.25), 4px 2px 4px rgba(0, 0, 0, 0.25)
}
.admin-login-popup input:not(:last-child){
    margin: 0 80px;
    margin-bottom: 12px;
    width: 200px;
    padding: 12px 12px;
    border-radius: 18px;
}
.admin-login-popup input:last-child{
    margin-top: 24px;
}
.admin-login-popup-title{
    font-weight: bold;
    text-align: left;
    margin-bottom: 12px;
}