.step {
    flex-direction: row;
}

.input-flat{
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    width: 100%;
    height: 32px;
}

.session-enrollment{
    display: flex;
    justify-content: space-between;
}

.session-enrollment .information{
    flex: 1;
    flex-direction: column;
    justify-content: start;
    padding: 0 24px;
}

.setup-forms input, textarea {
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    height: 24px;
    width: 80%;
}

.setup-forms textarea {
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    height: 120px;
    width: 80%;
}

.setup-forms input:focus, textarea:focus{
    box-shadow: yellow;
}

@media only screen and (max-width: 976px){
    .step {
        flex-direction: column;
        align-items: center;
        padding-bottom: 128px;
    }
}