.dropdown{
    position: absolute;
    top: 10%;
    right: 0px;
    background: #F1C40F;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    min-width: 200px;
    color: white;
}

.dropdown-item{
    padding: 8px 24px;
    border-bottom: white 1px solid;
    cursor: pointer;
}

.dropdown-item:hover{
    background: rgb(247, 204, 31);
}

.dropdown-item:last-child {
    border: none;
}