.main-profile-card{
    background: #FFFFFF;
    box-shadow: 2px -2px 2px rgba(0, 0, 0, 0.25), 2px 2px 2px rgba(0, 0, 0, 0.25), -2px 0px 2px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    padding: 24px 18px ;
    display: flex;
    min-width: 100px;
    box-sizing: border-box;
    /* align-items: center; */
}

.main-profile-card .bio{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    margin-left: 18px;
}

.main-profile-card table{
    border-spacing: 0px;
}
.main-profile-card table td{
    vertical-align: top;
    padding-right: 12px;
}

.stats-card{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: white;
    box-shadow: 2px -2px 2px rgba(0, 0, 0, 0.25), 2px 2px 2px rgba(0, 0, 0, 0.25), -2px 0px 2px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    min-width: 140px;
    padding: 12px 12px;
    padding-bottom: 0px;
    flex: 1;
}
.stats-card .count{
    font-weight: bold;
    
    color: #F1C40F;
}

.stats-card .title{
    text-align: center;
}

.counter-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 12px;
    align-items: center;
    height: 80%
}

.counter-title{
    
}

.counter-data{
    font-weight: bold;
    font-size: 40px;
    
}


.schedule-card{
    background: #FFFFFF;
    box-shadow: 2px -2px 2px rgba(0, 0, 0, 0.25), 2px 2px 2px rgba(0, 0, 0, 0.25), -2px 0px 2px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    margin: 0 24px;
    padding: 18px 24px;
    min-width: 260px;
    min-height: 240px;
}

.schedule-card table{
    width: 100%
}
.schedule-card table td +td{
    text-align: end;
}

.schedule-card .title{
    text-align: center;
}
.schedule-card .day-selector{
    color: #373434;
    text-align: center;
    font-weight: bold;
    margin-top: 32px;
    margin-bottom: 12px;
}

.schedule-day-selector{
    cursor: pointer;
}



@media only screen and (max-width: 768px){
    .main-profile-card{
        flex-direction: column;
        min-width: 90vw;
    }
    .stats-card{
        min-width: 125px;
    }
    .stats-card .count{
        font-weight: bold;
        font-size: 40px;
        color: #F1C40F;
    }
    .stats-card .title{
        font-size: 8px;
    }
    .schedule-card{
        margin: 0;
        margin-top: 24px;
    }
}



@media only screen and (max-width: 476px){
    .stats-card{
        min-width: 70px;
    }
}
