.service-container{
    display: flex;
    flex-direction: column;    
}

.service-quote{
    text-align: left;
    font-size: 24px;
    display: flex;
    max-width: 300px;
    font-weight: 500;
    justify-content: space-between;
    align-items: center;
}

.service-quote img{
    margin-right: 8px;
}

.service-description{
    max-width: 270px;
    margin-top: 24px;
}


.service-container img{
    object-fit: cover;
    /* width: 160px;
    height: 170px; */
    border-radius: 30px;
}

.yellow-bar {
    height: 6px;
    width: 264px;
    background-color: #4E73DF;
    margin: 22px 0 52px  0; 
}

