.navigation{
    flex: 1;
    background: #F1C40F;
    box-shadow: 6px 6px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    max-width: 36px;
    height: 70vh;
    margin-right: 8px;
    margin-left: 8px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    padding: 8px;
}
.icon-menu img{
    width: 40px;
    cursor: pointer;
}

.icon-menu-tooltip {
    display: none;
    position: absolute;
    margin-left: 24px;
    margin-top: 8px;
    padding: 0px 8px ;
    color: white;
    background: rgba(0, 0, 0, 0.75);
}

.icon-menu:hover .icon-menu-tooltip{
    display: inline;
}

@media only screen and (max-width: 918px){
    .navigation{
        flex: none;
        position: absolute;
        min-width: 100%;
        height: 60px;
        bottom: 0;
        left: 0;
        margin: 0;
        border-radius: 0;
        flex-direction: row;
        padding: 0px;
    }
}