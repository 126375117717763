.chat{
    flex: 1;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 90vh;
}

.chat .content{
    padding: 8px 0;
    flex-direction: column;
    overflow-y: hidden;
    height: 70vh;
    width: 100%;
    flex: 1;
}

.chat .chat-container{
    padding: 0 24px;
    height: 90%;
    overflow-y: scroll;
}

.chat .chat-controller{
    display: flex;
    align-items: center;
    padding: 8px;
}
.chat-controller input{
    width: 100%;
    height: 55px;
    background: #FFFFFF;
    border: 1px solid rgba(169, 165, 165, 0.87);
    box-sizing: border-box;
    border-radius: 100px;
    padding-left: 24px;
}

.chat-controller textarea{
    width: 100%;
    height: 55px;
    background: #FFFFFF;
    border: 1px solid rgba(169, 165, 165, 0.87);
    box-sizing: border-box;
    border-radius: 100px;
    padding-left: 24px;
    align-items: center;
    padding-top: 20px;
}

.chat-controller textarea::placeholder{
    height: 100%;
    line-height: 90%;
}

.chat-controller img{
    width: 70ppx;
    height: 70px;
    cursor: pointer;
}

.chat .bubble{
    width: 100%;
    display: flex;
    flex-direction: row-reverse;

    width: intrinsic;           /* Safari/WebKit uses a non-standard name */
    width: -moz-max-content;    /* Firefox/Gecko */
    width: -webkit-max-content; /* Chrome */
    width: fit-content;
}

.conselor-bubble{
    display: flex;
    flex-direction: row;
}

.chat-date{
    background: #7AD6FF;
    box-shadow: -5px 5px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    text-align: center;
    max-width: 100px;
    margin: 0 auto;
    padding: 4px 0;
}