.information{
    flex: 1;
    box-sizing: border-box;
}

.information .content{
    padding: 24px;
    flex-direction: column;
    overflow-y: scroll;
    max-height: 68vh;
}
.information .content .avatar{
    width: 60px;
    height: 60px;
}

.conselor-conference{
    display: flex;
    align-items: center;
    margin-bottom: 24px;
}

.conselor-conference .conference-name{
    min-width: 300px;
    padding-left: 24px;
}
