.ganti-password-container{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: red; */
}

.ganti-password-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, .55);
    padding: 64px 14px;
    border-radius: 12px;
}
.ganti-password-card input{
    margin-top: 8px;
    width: 400px;
    padding: 12px 4px;
 }
