.conseli-profile-card{
    padding: 24px 24px;
    background: #FFFFFF;
    box-shadow: 2px -2px 2px rgba(0, 0, 0, 0.25), 2px 2px 2px rgba(0, 0, 0, 0.25), -2px 0px 2px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    display: flex;
    margin: 24px;
    flex-direction: column;
}

.conseli-profile-buttons{
    display: flex;
    justify-content: space-evenly;    
    width: 55%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 16px;
}

.conseli-profile-card table td{
    padding-left: 54px;
}

.conseli-profile-card table {
}

.conseli-profile-card table td:first-child{
    padding-left: 0px;
}

.conseli-profile-card table td:last-child{
    width: 220px
}

.conseli-profile-avatar{
    background-color: gray;
    width: 100px;
    height: 100px;
    border-radius: 400px;
}