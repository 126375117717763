.content{
    flex-direction: column;
}

.arrow {
  pointer-events: none;
}
 
.arrow__path {
  stroke: #000;
  fill: transparent;
  stroke-dasharray: 4 2;
}
 
.arrow__head line {
  stroke: red;
  stroke-width: 1px;
}