.appbar{
    position: static;
    top: 0;
    max-width: 100vw;
    height: 10vh;
    background-color: white;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
    padding: 10px 17px;
    box-sizing: border-box;
}   

.logo{
    max-height: 80%;
    cursor: pointer;
}
.row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-height: 100%;
}

.icons{
    align-items: center;
    width: 200px;
}

/* .icons:nth-child(2){
    width: 48px;
} */
.icons .message-icon{
    width: 32px;
    cursor: pointer;
}

.notification{
    object-fit: contain;
    height: 40px;
    width: 40px;
    cursor: pointer;
}

.red-dot{
    background-color: red;
    width: 14px;
    height: 14px;
    display: flex;
    border-radius: 100px;
    position: absolute;
    margin-left: 32px;
}

.admin-icons{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    padding-right: 8px;
}
.admin-icons:last-child{
    margin-left: 8px;
}